<template>
  <div>
    <!-- <v-row dense>
      <v-col cols="12" md="6"
        ><base-selector
          v-model="search"
          :items="
            partsCategories.map(c => ({
              text: $tc(`inventory.categories.${c.value}`, 1),
              value: c.value
            }))
          "
          :label="
            `${$t('common.name')}
        (*)`
          "
          :placeholder="$t('inventory.categories.back')"
          clearable
          @input="filterItemProducts"
      /></v-col>
    </v-row> -->
    <InventoryItemIndexTableFilter @filter="filterItemProducts" />
    <ItemUnitsOnOrderEditFormTable ref="table" />
  </div>
</template>

<script>
import { PARTS_CATEGORIES, INVENTORY_CATEGORIES } from '@/common/terms'
import ItemUnitsOnOrderEditFormTable from './ItemUnitsOnOrderEditFormTable'
import InventoryItemIndexTableFilter from '@/views/inventory-item/components/InventoryItemIndexTableFilter'
export default {
  components: {
    ItemUnitsOnOrderEditFormTable,
    InventoryItemIndexTableFilter
  },
  data() {
    return {
      search: null,
      items: [],
      partsCategories: [...PARTS_CATEGORIES, ...INVENTORY_CATEGORIES]
    }
  },
  methods: {
    // filterItemProducts(name) {
    //   this.$refs.table.fetchData(name)
    // }
    filterItemProducts(filter) {
      let options = Object.assign({}, this.options)
      options = { ...options, ...filter }

      this.$store.dispatch('itemEndProduct/setPagination', options)
      this.$refs.table.fetchData()
    }
  }
}
</script>

<style lang="scss" scoped></style>
