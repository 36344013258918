<template>
  <base-form-layout
    :title="
      `${$t('selectors.select')} ${$tc('product.part', 1)} ${$t(
        'common.from'
      )} ${$t('inventory.name')}`
    "
    size="large"
    @submit="onSubmit"
  >
    <v-form ref="form">
      <ItemUnitsOnOrderEditForm />
    </v-form>
  </base-form-layout>
</template>

<script>
import ItemUnitsOnOrderEditForm from './components/ItemUnitsOnOrderEditForm'
import { ORGANIZATION_ID } from '@/common/config.js'
import { ITEM_UNIT_ON_ORDER } from '@/models/item.unit'
import { FormMixin } from '@/mixins/form.mixin'
import { DocumentMixin } from '@/mixins/document.mixin.js'
import { mapGetters } from 'vuex'
export default {
  name: 'ItemUnitOnOrderEdit',
  components: {
    ItemUnitsOnOrderEditForm
  },
  mixins: [FormMixin, DocumentMixin],
  props: {
    orderId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      itemUnitOnOrder: ITEM_UNIT_ON_ORDER(),
      clientId: null,
      isLoading: true
    }
  },
  computed: {
    ...mapGetters({ itemProducts: 'itemEndProduct/items' })
  },
  async created() {
    await this.fetchData()
  },

  methods: {
    async fetchData() {
      this.isLoading = true
      this.itemUnitOnOrder.attributes.orderId = this.orderId
      this.clientId = await this.fetchClientId()
      this.isLoading = false
    },
    async fetchClientId() {
      const response = await this.$store.dispatch('party/filter', {
        orderIds: this.orderId
      })
      if (response.length) return response[0].attributes.userId
    },
    async onSubmit() {
      this.beforeSubmit = false
      for await (const item of this.itemProducts) {
        const {
          id,
          attributes: { quantity }
        } = item
        if (!quantity) continue
        this.itemUnitOnOrder.id = id
        this.itemUnitOnOrder.attributes.quantity = quantity
        this.itemUnitOnOrder.attributes.organizationId = ORGANIZATION_ID()
        this.itemUnitOnOrder.attributes.clientId = this.clientId
        await this.createItemUnitOnOrder(this.itemUnitOnOrder)
      }

      this.$router.go(-1)
    },
    async createItemUnitOnOrder(itemUnitOnOrder) {
      await this.$store.dispatch('itemUnit/update', {
        data: itemUnitOnOrder,
        attribute: 'units-on-order'
      })
    }
  }
}
</script>
