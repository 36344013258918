<template>
  <v-data-table
    id="product-items-table"
    :headers="headers"
    :items="itemProducts"
    :loading="isLoading"
    item-key="id"
    loader-height="1"
    :page.sync="options.page"
    :server-items-length="meta.totalPages"
    @click:row="$emit('click:row', $event)"
  >
    <template v-slot:item.attributes.name="{ item }">
      {{ $t(`inventory.categories.${item.attributes.name}`) }}
    </template>

    <template v-slot:item.customName="{ item }">
      {{
        item.attributes.customAttributes &&
          item.attributes.customAttributes.customName | titleCase
      }}
    </template>

    <template v-slot:item.specie="{ item }">
      {{ $t(`species.${item.attributes.customAttributes.specie.text}`) }}
    </template>

    <template v-slot:item.dimensions="{ item }">
      <ul>
        <li v-if="item.attributes.dimensions.length">
          <span class="text-caption grey--text"> L</span>
          {{
            toMeters(
              item.attributes.dimensions.length,
              item.attributes.dimensionsUnit
            ) || '·'
          }}
          m
        </li>
        <li v-if="item.attributes.dimensions.width">
          <span class="text-caption grey--text"> W </span>
          {{
            toCentimeters(
              item.attributes.dimensions.width,
              item.attributes.dimensionsUnit
            ) || '·'
          }}
          cm
        </li>
        <li v-if="item.attributes.dimensions.height">
          <span class="text-caption grey--text"> T </span>
          {{
            toMillimeters(
              item.attributes.dimensions.height,
              item.attributes.dimensionsUnit
            ) || '·'
          }}
          mm
        </li>

        <li v-if="item.attributes.volume">
          <span class="grey--text"> Vol. </span>
          {{ item.attributes.volume || '·' }}
          <span
            v-if="item.attributes.volume && item.attributes.volumeUnit !== 'm³'"
            >{{ item.attributes.volumeUnit }}</span
          >
        </li>
      </ul>
    </template>
    <template v-slot:item.quantity="{ item }">
      <QuantityField
        :maximum="true"
        :units-on-order="true"
        dense
        filled
        :item="item"
      />
    </template>
    <template v-slot:footer>
      <v-pagination
        v-model="options.page"
        light
        circle
        class="py-4"
        :length="meta.totalPages"
        total-visible="15"
        @input="onPagination"
      ></v-pagination>
    </template>
  </v-data-table>
</template>

<script>
import { mapGetters } from 'vuex'
import { ORGANIZATION_ID } from '@/common/config.js'
import QuantityField from '@/components/QuantityField'
import { UnitsConversion } from '@/mixins/units.conversion.mixin'
export default {
  name: 'ItemProductsIndexTable',
  components: { QuantityField },
  mixins: [UnitsConversion],
  data() {
    return {
      isLoading: true
    }
  },
  computed: {
    ...mapGetters({
      isAdmin: 'auth/isAdmin',
      itemProducts: 'itemEndProduct/items',
      meta: 'itemEndProduct/meta',
      options: 'itemEndProduct/options'
    }),
    headers() {
      return this.createTableHeaders()
    }
  },

  created() {
    this.fetchData()
    this.$store.dispatch('itemEndProduct/setPagination', {
      page: 1,
      itemsPerPage: 10
    })
  },

  methods: {
    async fetchData() {
      this.isLoading = true
      await this.filterItemProducts()
      this.isLoading = false
    },
    async onPagination() {
      this.isLoading = true
      this.$store.dispatch('itemEndProduct/setPagination', this.options)
      this.$vuetify.goTo('#product-items-table', 'easeInOutCubic')
      await this.filterItemProducts()
      this.isLoading = false
    },

    async filterItemProducts() {
      const {
        status,
        woodCategory,
        itemGroup,
        specie,
        page,
        name,
        itemsPerPage
      } = this.options
      const params = {
        organizationId: ORGANIZATION_ID(),
        'page[number]': page,
        'page[size]': itemsPerPage,
        sort: '-updated_at',
        status,
        woodCategory,
        itemGroup,
        name,
        specie
      }

      await this.$store.dispatch('itemEndProduct/filter', params)
    },
    createTableHeaders() {
      return [
        {
          text: this.$t('common.name'),
          align: 'start',
          sortable: false,
          value: 'attributes.name'
        },
        {
          text: this.$t('item.description'),
          align: 'start',
          sortable: false,
          value: 'attributes.description'
        },
        {
          text: this.$t('item.specie'),
          value: 'specie',
          sortable: false
        },
        {
          text: this.$tc('common.category', 1),
          value: 'attributes.customAttributes.category',
          sortable: false
        },
        {
          text: this.$t('item.dimensions'),
          sortable: false,
          align: 'end',
          value: 'dimensions'
        },
        {
          text: this.$t('inventory.in_stock'),
          sortable: false,
          value: 'attributes.itemUnitsInStockCount'
        },
        {
          text: this.$t('item.quantity'),
          sortable: false,
          align: 'center',
          value: 'quantity'
        }
      ]
    }
  }
}
</script>
